<template>
  <v-container class="wrapper" fill-height fluid>
    <v-layout>
      <v-flex :style="{ backgroundImage: `url(${heroImage})` }" md6 hidden-sm-and-down class="hero">
        <icon-button class="home-button" @click="onHomeClick">
          <home-icon/>
        </icon-button>
      </v-flex>
      <v-flex md6>
        <v-container fill-height>
          <v-layout align-center fill-height>
            <v-flex>
              <div class="content">
                <slot/>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import IconButton from '@/components/atoms/IconButton.vue';
import HomeIcon from '@/assets/icons/home.svg';
import { HOME } from '@/constants/routes';

export default {
  components: {
    IconButton,
    HomeIcon
  },
  props: {
    heroImage: {
      type: String,
      default: ''
    }
  },
  methods: {
    onHomeClick() {
      window.location.href = HOME;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixins.scss';

.wrapper {
  padding: 0;
}
.hero {
  position: relative;
  width: 50%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.home-button {
  position: absolute;
  top: rem(40px);
  left: rem(40px);
}

.content {
  margin: 0 auto;
  max-width: 100%;
  width: rem(450px);
}
</style>
